<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'

export default {
  name: 'theClientDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {}
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail.call(this, {
        url: `/client/view/${id}`
      })
  },
  methods: {
    getForm1() {
      return {
        title: '客户端信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '客户端ID',
            type: 'input',
            cols: 12,
            key: 'clientId'
          },
          {
            name: '客户端密钥',
            type: 'input',
            cols: 12,
            key: 'clientSecret'
          }
        ]
      }
    },
    getForm2() {
      return {
        title: '授权信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '授权方式',
            type: 'input',
            cols: 12,
            key: 'authorizedGrantTypes'
          },
          {
            name: '权限列表',
            type: 'input',
            cols: 12,
            key: 'authorities'
          },
          {
            name: '回调地址',
            type: 'input',
            cols: 12,
            key: 'webServerRedirectUri'
          }
        ]
      }
    },
    getForm3() {
      return {
        title: '令牌信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '请求令牌有效时间',
            type: 'input',
            cols: 12,
            key: 'accessTokenValidity'
          },
          {
            name: '刷新令牌有效时间',
            type: 'input',
            cols: 12,
            key: 'refreshTokenValidity'
          }
        ]
      }
    },
    getForm4() {
      return {
        title: '扩展信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '扩展信息',
            type: 'input',
            cols: 12,
            key: 'additionalInformation'
          },
          {
            name: '是否自动放行',
            type: 'input',
            cols: 12,
            key: 'autoapprove'
          },
          {
            name: '资源id列表',
            type: 'input',
            cols: 12,
            key: 'resourceIds'
          },
          {
            name: '作用域',
            type: 'input',
            cols: 12,
            key: 'scope'
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true, //TODO
          onClick: data => {
            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/client',
                params: {
                  ...data
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/system/theClient')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/system/theClient')
          }
        }
      ]
      return {
        left
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getForm2(), this.getForm3(), this.getForm4()]}
      />
    )
  }
}
</script>
